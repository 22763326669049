export default {
  common: {
    clickToSelectAnImage: `Click to select an image or upload your own`,
    requiredFields: 'Required fields',
  },
  commuteBoost: {
    challengeName: {
      label: 'Challenge name',
      placeholder: 'e.g. February walking challenge',
      helperText:
        'What should the challenge be called? Displayed to the user in both snippet and expanded view.',
    },
    challengeDescription: {
      label: 'Description',
      placeholder:
        'e.g. Stay active and earn yourself a free lunch at the end of the month. All you have to do is walk to work 14 days in January. Go on, you know you can do it!',
      helperText: 'A bit more detail. Only visible to the user in the expanded view.',
    },
    challengeOneLiner: {
      label: 'Challenge one-liner',
      placeholder: 'e.g. Walk to work 14 times in June and get a free coffee',
      helperText:
        "Short, sweet one-liner to get the user's attention.",
    },
    challengeImageBanner: {
      label: 'Banner image',
      helperText: 'Ideally 800px by 400px.',
      buttonText: 'Upload image',
    },
    challengeImageThumbnail: {
      label: 'List image',
      helperText: 'Used in the list of challenges.',
    },
    challengeTransportMode: {
      label: 'Transport mode',
      helperText: 'A matching icon will be shown to the user in both snippet and expanded view.',
      options: {
        walking: 'Walking',
        cycling: 'Cycling',
        carsharing: 'Carpooling',
      },
    },
    challengeLength: {
      label: 'Challenge length'
    },
    ongoing: {
      label: 'Is this an ongoing challenge?',
      helperText: 'Challenges can be ongoing or have a fixed end date.',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    challengeStartDate: {
      label: 'Start date',
      placeholder: 'Select a start date',
      helperText: 'Displayed to the user in both views.',
    },
    challengeEndDate: {
      label: 'End date',
      placeholder: 'Select an end date',
      helperText: 'Displayed to the user in both views.',
    },
    challengeNumberOfRequiredJourneys: {
      label: 'Number of required journeys',
      helperText: 'How many journeys does the user need to do to get the reward?',
    },
    challengeMinimumDistance: {
      label: 'Minimum distance (metres)',
      helperText: '500m is roughly a five-minute walk.',
    },
    challengeHasTimeWindow: {
      label: 'Restrict journeys to a time window?',
      helperText: 'Is there a time window during which journeys must be taken?',
      description: 'The employee\'s journey arrival time must be within the time window to qualify for the challenge',
      options: {
        false: 'No time window',
        true: 'Yes, Set a time window',
      },
    },
    challengeTimeWindowDetails: {
      label: 'Restrict journeys',
      daysField: {
        label: 'Select the days that journeys can qualify',
        setTimeOptions: {
          false: 'No',
          true: 'Yes',
        },
        startTime: {
          label: 'Window start time (nearest hour)',
          helperText: 'When should the window begin? (0-23 hour)',
        },
        duration: {
          label: 'Window duration (minutes)',
          helperText: 'After how many minutes should the window close?',
        },
      },
    },
    challengeUnlimitedEntrants: {
      label: 'Allow unlimited entrants?',
      helperText: 'Use this to set a maximum number of claims for the reward.',
      options: {
        false: 'Set a maximum number',
        true: 'Yes, unlimited entrants',
      },
    },
    challengeMaxEntrants: {
      label: 'Maximum number of entrants',
    },
    challengeParticipationRules: {
      label: 'Participation rules',
      placeholder: 'e.g. "Only journeys to work count" or "Journeys must be at least 500m"',
      helperText: 'What are the rules for taking part? You can add headings, links and lists.',
    },
    boostName: {
      label: 'Boost name',
      placeholder: 'e.g. Free coffee',
      helperText: 'What should the boost be called?',
    },
    boostDescription: {
      label: 'Description',
      placeholder: 'e.g. A free coffee from the canteen',
      helperText: 'A bit more detail. Only visible to the user in the expanded view.',
    },
    boostImageUrl: {
      label: 'Boost image',
      helperText: 'Displayed to the user in several places in the app. Should be square.',
    },
    boostIssueType: {
      label: 'When to issue?',
      helperText: 'Once earned, when can the user redeem it?',
      options: {
        immediately: 'Immediately',
        endOfChallenge: 'End of challenge',
      },
    },
    boostExpiryDate: {
      label: 'Expiry date',
      helperText: 'When does the boost expire?',
    },
    boostRedemptionType: {
      label: 'Redemption method',
      helperText: 'How will the user redeem the boost?',
      options: {
        code: 'Provide the user with a unique redemption code',
        manual: 'Manual redemption',
      },
    },

    boostAvailability: {
      label: 'Boost availability',
      helperText: 'How many people can redeem this boost?',
      options: {
        limited: 'Limited',
        unlimited: 'Unlimited',
      },
    },

    boostAvailabilityMaxRedemptions: {
      label: 'Number of boosts available for this challenge',
      helperText: ''
    },

    boostRedemptionUserClaimFrequency: {
      label: 'Participation (number of times employees can partake in a challenge)',
      helperText: '',
      options: {
        oncePerChallenge: 'Once per challenge',
        multipleTimes: 'Multiple times',
      }
    },

    maxBoostUserRedemptions: {
      label: 'Enter the number of times the user can partake (leave blank for unlimited)',
      helperText: ''
    },

    boostFullDetails: {
      label: 'Full details',
      placeholder:
        'e.g. On completion of the challenge, you will be able to redeem one free coffee from the staff canteen using the redemption code provided. The code will expire after 7 days.',
      helperText:
        'Provide further details to help the user understand the boost.  You can add headings, links and lists.',
    },
    challengeTerms: {
      label: 'Terms and conditions',
      placeholder: 'e.g. Full terms...',
      helperText:
        'Full details of the terms and conditions for the challenge.  You can add headings, links and lists.',
    },
    challengeScheduled: {
      label: 'Go live with this survey immediately?',
      helperText: 'You will be asked to confirm this action before the survey is published.',
      options: {
        true: 'No, schedule a go live date',
        false: 'Yes, go live immediately',
      },
    },
    challengeVisibleOnAppDate: {
      label: 'When should this challenge appear in the app?',
      placeholder: 'Select date',
      helperText:
        'To show in the App immediately, select today. Otherwise, select a future date. Note: The date must be on or before the challenge start date. If it is before, members will see the Challenge in the App but they will not be able to enrol until the challenge starts.',
    },
  },
};
