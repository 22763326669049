import { createApp } from 'vue';
import { createPinia } from 'pinia';

// i18n support
import { createI18n } from 'vue-i18n';
import { enGB } from '@/locales/en-gb';
type MessageSchema = typeof enGB;

import Toast, { type PluginOptions, POSITION } from 'vue-toastification';

import App from '@/App.vue';

import VueGtag from 'vue-gtag';
import router from '@/router';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import vClickout from 'vue3-clickout';
import { store } from '@/mw-components/store';
import '@/scss/main.scss';
import 'vue-toastification/dist/index.css';
import 'nprogress/nprogress.css';

import './assets/themes/lara-light/mobilityways/theme.scss';
import 'primevue/resources/primevue.min.css';

import * as Sentry from '@sentry/vue';
import { getEnv } from './plugin/functions';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { vueQueryOptions } from '@/api/vue-query-options';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

const mw = createApp(App);
const pinia = createPinia();
const i18n = createI18n<[MessageSchema], 'en-GB'>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB', // set fallback locale
  legacy: false,
  messages: {
    'en-GB': enGB,
  },
});
const vfm = createVfm();
console.log(`Vite Env: ${import.meta.env.MODE}
Run Env: ${getEnv()}
Git SHA: ${__COMMIT_HASH__}`);

/**
 * Configure sentry for error reporting on production & development
 * mobilityways-dashboard-web project:
 */
Sentry.init({
  app: mw,
  dsn: 'https://eaf9b0dd39c0bf570bc168c00cf230e0@o4507888145465344.ingest.de.sentry.io/4507928102895696',
  environment: getEnv(),
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // @todo: If we definitely don't want any backend instrumentation, remove these
  tracePropagationTargets: [
    'https://interface.liftshare.com',
    'https://interface-dev.liftshare.com',
    'https://interface-staging.liftshare.com',
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // True for development-dashboard.mobilityways.co.uk and dashboard.mobilityways.com
  enabled: import.meta.env.MODE === 'production',
});

const options: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 5000,
};

mw.use(pinia);
mw.use(i18n);
mw.use(ConfirmationService);
mw.use(vfm);
mw.use(router);
mw.use(VueQueryPlugin, vueQueryOptions);
mw.use(PrimeVue);
mw.directive('tooltip', Tooltip);
mw.use(store);
mw.use(
  VueGtag,
  {
    appName: 'MW Dashboard',
    pageTrackerEnabled: true,
    pageTrackerScreenviewEnabled: true,
    config: {
      id: 'G-Z5HNKRMM4X',
      params: {
        debug_mode: !(getEnv() === 'production'),
      },
    },
    pageTrackerTemplate(to: any) {
      return {
        page_title: to.meta && to.meta.title ? to.meta.title : to.name,
        page_path: to.path,
      };
    },
  },
  router,
);
mw.use(vClickout);
mw.use(Toast, options);
mw.mount('#app');
